const config = {
  ENVIRONMENT_NAME: 'testing',
  forwoodId: {
    URL: 'https://id.testing.laika.forwoodsafety.com',
    APP_CLIENT_ID: '2vn5e8qgv6iujph2r1imp6foel',
    APIGATEWAY: 'https://8f47tcgt08.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://u9edxa2mv9.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'testing',
    VERSION: 'incidents-container-1.4.0',
    HOSTNAME: 'https://incidents.testing.laika.forwoodsafety.com',
    INCIDENTS_ACCESS_ROLE: 'IncidentsAccess',
    CREATE_INCIDENT_URL: 'https://iframe.testing.laika.forwoodsafety.com/node/add/isi',
    INCIDENT_LIST_URL: 'https://iframe.testing.laika.forwoodsafety.com/isi-list',
    IFRAME_ORIGIN_URL: 'https://iframe.testing.laika.forwoodsafety.com'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;

